define('ember-ux-sauce/components/uxs-tiles/tile', ['exports', 'ember-ux-sauce/templates/components/uxs-tiles/tile', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _tile, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_testable.default, _bemComponent.default, {
    layout: _tile.default,
    // Attributes
    /**
      The base css class name 'uxs-tiles__item'
       @field base
      @type String
     */
    base: 'uxs-tiles__item',
    /**
      Set the style of the splash component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-splash--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['scroll', '*size', '*style']);
    }
  });
});