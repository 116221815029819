define('ember-ux-sauce/components/uxs-menu/link', ['exports', 'ember-ux-sauce/templates/components/uxs-menu/link', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _link, _styleable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_bemComponent.default, _styleable.default, _testable.default, {
    layout: _link.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-menu__item"
      @public
     */
    base: 'uxs-menu__item',
    /**
      If true, the link will be displayed horizontally
       @argument horizontal
      @type     boolean
      @default  false
      @public
     */
    horizontal: false,
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    /**
      If set to true the link will be rendered small (detail) size
       @argument detail
      @default false
      @type Boolean
    */
    detail: false,
    /**
      If set to true the link will include a left padding to account for icons.
       @argument hasLeftIcon
      @default false
      @type Boolean
    */
    hasLeftIcon: false,
    /**
      If set to true the link will include a right padding to account for icons.
       @argument hasRightIcon
      @default false
      @type Boolean
    */
    hasRightIcon: false,
    /**
      If set the link will contain an icon on the right
       @argument rightIcon
      @default  null
      @type     String
      @public
    */
    rightIcon: null,
    /**
      If set the link will contain an icon on the left
       @argument leftIcon
      @default  null
      @type     String
      @public
    */
    leftIcon: null,
    /**
      If set to true the link will include padding to account for secondary text.
       @argument hasSecondaryText
      @default false
      @type Boolean
    */
    hasSecondaryText: false,
    /**
      Sets secondary text in the link
       @argument secondaryText
      @default  null
      @type     String
      @public
    */
    secondaryText: null,
    // Computed
    showRightIcon: Ember.computed('hasRightIcon', 'rightIcon', 'hasSecondaryText', 'secondaryText', function () {
      return Ember.get(this, 'hasRightIcon') || Ember.get(this, 'rightIcon') || Ember.get(this, 'hasSecondaryText') || Ember.get(this, 'secondaryText');
    }),
    showLeftIcon: Ember.computed('hasLeftIcon', 'leftIcon', function () {
      return Ember.get(this, 'hasLeftIcon') || Ember.get(this, 'leftIcon');
    }),
    // Methods
    init: function init() {
      this.registerModifiers(['detail', 'horizontal', 'disabled']);
      this._super.apply(this, arguments);
    },
    click: function click() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'showingModal') === true) {
        Ember.set(this, 'showingModal', false);
      }
    }
  });
});