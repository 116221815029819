enifed('ember-runtime/lib/mixins/array', ['exports', '@ember/deprecated-features', 'ember-metal', 'ember-utils', '@ember/debug', 'ember-runtime/lib/mixins/enumerable', 'ember-runtime/lib/compare', 'ember-environment', 'ember-runtime/lib/mixins/observable', 'ember-runtime/lib/copy', '@ember/error', 'ember-runtime/lib/mixins/mutable_enumerable', 'ember-runtime/lib/type-of'], function (exports, _deprecatedFeatures, _emberMetal, _emberUtils, _debug, _enumerable, _compare, _emberEnvironment, _observable, _copy, _error, _mutable_enumerable, _typeOf) {
  'use strict';

  exports.MutableArray = exports.NativeArray = exports.A = undefined;
  exports.isEmberArray = function (obj) {
    return obj && obj[EMBER_ARRAY];
  };
  exports.uniqBy = uniqBy;
  exports.isArray = isArray;
  exports.removeAt = removeAt;

  var _Mixin$create, _NativeArray;

  var EMPTY_ARRAY = Object.freeze([]);
  var EMBER_ARRAY = (0, _emberUtils.symbol)('EMBER_ARRAY');

  var identityFunction = function (item) {
    return item;
  };

  function uniqBy(array) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identityFunction;
    false && !isArray(array) && (0, _debug.assert)('first argument passed to `uniqBy` should be array', isArray(array));

    var ret = A();
    var seen = new Set();
    var getter = typeof key === 'function' ? key : function (item) {
      return (0, _emberMetal.get)(item, key);
    };

    array.forEach(function (item) {
      var val = getter(item);
      if (!seen.has(val)) {
        seen.add(val);
        ret.push(item);
      }
    });

    return ret;
  }

  function iter(key, value) {
    var valueProvided = arguments.length === 2;
    return valueProvided ? function (item) {
      return value === (0, _emberMetal.get)(item, key);
    } : function (item) {
      return !!(0, _emberMetal.get)(item, key);
    };
  }

  function findIndex(array, predicate, startAt) {
    var len = array.length,
        index,
        item;
    for (index = startAt; index < len; index++) {
      item = (0, _emberMetal.objectAt)(array, index);

      if (predicate(item, index, array)) {
        return index;
      }
    }
    return -1;
  }

  function find(array, callback, target) {
    var predicate = callback.bind(target);
    var index = findIndex(array, predicate, 0);
    return index === -1 ? undefined : (0, _emberMetal.objectAt)(array, index);
  }

  function any(array, callback, target) {
    var predicate = callback.bind(target);
    return findIndex(array, predicate, 0) !== -1;
  }

  function every(array, callback, target) {
    var cb = callback.bind(target);

    return findIndex(array, function (item, index, array) {
      return !cb(item, index, array);
    }, 0) === -1;
  }

  function indexOf(array, val) {
    var startAt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var withNaNCheck = arguments[3];

    var len = array.length;

    if (startAt < 0) {
      startAt += len;
    }

    // SameValueZero comparison (NaN !== NaN)
    var predicate = withNaNCheck && val !== val ? function (item) {
      return item !== item;
    } : function (item) {
      return item === val;
    };
    return findIndex(array, predicate, startAt);
  }

  /**
    Returns true if the passed object is an array or Array-like.
  
    Objects are considered Array-like if any of the following are true:
  
      - the object is a native Array
      - the object has an objectAt property
      - the object is an Object, and has a length property
  
    Unlike `typeOf` this method returns true even if the passed object is
    not formally an array but appears to be array-like (i.e. implements `Array`)
  
    ```javascript
    import { isArray } from '@ember/array';
    import ArrayProxy from '@ember/array/proxy';
  
    isArray();                                      // false
    isArray([]);                                    // true
    isArray(ArrayProxy.create({ content: [] }));    // true
    ```
  
    @method isArray
    @static
    @for @ember/array
    @param {Object} obj The object to test
    @return {Boolean} true if the passed object is an array or Array-like
    @public
  */
  function isArray(_obj) {
    var obj = _obj;


    if (!obj || obj.setInterval) {
      return false;
    }
    if (Array.isArray(obj) || ArrayMixin.detect(obj)) {
      return true;
    }

    var type = (0, _typeOf.typeOf)(obj);
    if ('array' === type) {
      return true;
    }
    var length = obj.length;
    if (typeof length === 'number' && length === length && 'object' === type) {
      return true;
    }
    return false;
  }

  // ..........................................................
  // ARRAY
  //
  /**
    This mixin implements Observer-friendly Array-like behavior. It is not a
    concrete implementation, but it can be used up by other classes that want
    to appear like arrays.
  
    For example, ArrayProxy is a concrete classes that can
    be instantiated to implement array-like behavior. Both of these classes use
    the Array Mixin by way of the MutableArray mixin, which allows observable
    changes to be made to the underlying array.
  
    This mixin defines methods specifically for collections that provide
    index-ordered access to their contents. When you are designing code that
    needs to accept any kind of Array-like object, you should use these methods
    instead of Array primitives because these will properly notify observers of
    changes to the array.
  
    Although these methods are efficient, they do add a layer of indirection to
    your application so it is a good idea to use them only when you need the
    flexibility of using both true JavaScript arrays and "virtual" arrays such
    as controllers and collections.
  
    You can use the methods defined in this module to access and modify array
    contents in a KVO-friendly way. You can also be notified whenever the
    membership of an array changes by using `.observes('myArray.[]')`.
  
    To support `EmberArray` in your own class, you must override two
    primitives to use it: `length()` and `objectAt()`.
  
    @class EmberArray
    @uses Enumerable
    @since Ember 0.9.0
    @public
  */
  var ArrayMixin = _emberMetal.Mixin.create(_enumerable.default, (_Mixin$create = {}, _Mixin$create[EMBER_ARRAY] = true, _Mixin$create.objectsAt = function (indexes) {
    var _this = this;

    return indexes.map(function (idx) {
      return (0, _emberMetal.objectAt)(_this, idx);
    });
  }, _Mixin$create['[]'] = (0, _emberMetal.computed)({
    get: function () {
      return this;
    },
    set: function (key, value) {
      this.replace(0, this.length, value);
      return this;
    }
  }), _Mixin$create.firstObject = (0, _emberMetal.computed)(function () {
    return (0, _emberMetal.objectAt)(this, 0);
  }).readOnly(), _Mixin$create.lastObject = (0, _emberMetal.computed)(function () {
    return (0, _emberMetal.objectAt)(this, this.length - 1);
  }).readOnly(), _Mixin$create.slice = function () {
    var beginIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var endIndex = arguments[1];

    var ret = A();
    var length = this.length;

    if (beginIndex < 0) {
      beginIndex = length + beginIndex;
    }

    if (endIndex === undefined || endIndex > length) {
      endIndex = length;
    } else if (endIndex < 0) {
      endIndex = length + endIndex;
    }

    while (beginIndex < endIndex) {
      ret[ret.length] = (0, _emberMetal.objectAt)(this, beginIndex++);
    }

    return ret;
  }, _Mixin$create.indexOf = function (object, startAt) {
    return indexOf(this, object, startAt, false);
  }, _Mixin$create.lastIndexOf = function (object, startAt) {
    var len = this.length,
        idx;

    if (startAt === undefined || startAt >= len) {
      startAt = len - 1;
    }

    if (startAt < 0) {
      startAt += len;
    }

    for (idx = startAt; idx >= 0; idx--) {
      if ((0, _emberMetal.objectAt)(this, idx) === object) {
        return idx;
      }
    }

    return -1;
  }, _Mixin$create.addArrayObserver = function (target, opts) {
    return (0, _emberMetal.addArrayObserver)(this, target, opts);
  }, _Mixin$create.removeArrayObserver = function (target, opts) {
    return (0, _emberMetal.removeArrayObserver)(this, target, opts);
  }, _Mixin$create.hasArrayObservers = (0, _emberMetal.computed)(function () {
    return (0, _emberMetal.hasListeners)(this, '@array:change') || (0, _emberMetal.hasListeners)(this, '@array:before');
  }), _Mixin$create.arrayContentWillChange = function (startIdx, removeAmt, addAmt) {
    return (0, _emberMetal.arrayContentWillChange)(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.arrayContentDidChange = function (startIdx, removeAmt, addAmt) {
    return (0, _emberMetal.arrayContentDidChange)(this, startIdx, removeAmt, addAmt);
  }, _Mixin$create.forEach = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null,
        index,
        item;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`forEach` expects a function as first argument.', typeof callback === 'function');

    var length = this.length;

    for (index = 0; index < length; index++) {
      item = this.objectAt(index);

      callback.call(target, item, index, this);
    }

    return this;
  }, _Mixin$create.getEach = (0, _emberMetal.aliasMethod)('mapBy'), _Mixin$create.setEach = function (key, value) {
    return this.forEach(function (item) {
      return (0, _emberMetal.set)(item, key, value);
    });
  }, _Mixin$create.map = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`map` expects a function as first argument.', typeof callback === 'function');

    var ret = A();

    this.forEach(function (x, idx, i) {
      return ret[idx] = callback.call(target, x, idx, i);
    });

    return ret;
  }, _Mixin$create.mapBy = function (key) {
    return this.map(function (next) {
      return (0, _emberMetal.get)(next, key);
    });
  }, _Mixin$create.filter = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`filter` expects a function as first argument.', typeof callback === 'function');

    var ret = A();

    this.forEach(function (x, idx, i) {
      if (callback.call(target, x, idx, i)) {
        ret.push(x);
      }
    });

    return ret;
  }, _Mixin$create.reject = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`reject` expects a function as first argument.', typeof callback === 'function');

    return this.filter(function () {
      return !callback.apply(target, arguments);
    });
  }, _Mixin$create.filterBy = function () {
    return this.filter(iter.apply(undefined, arguments));
  }, _Mixin$create.rejectBy = function () {
    return this.reject(iter.apply(undefined, arguments));
  }, _Mixin$create.find = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`find` expects a function as first argument.', typeof callback === 'function');

    return find(this, callback, target);
  }, _Mixin$create.findBy = function () {
    return find(this, iter.apply(undefined, arguments));
  }, _Mixin$create.every = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`every` expects a function as first argument.', typeof callback === 'function');

    return every(this, callback, target);
  }, _Mixin$create.isEvery = function () {
    return every(this, iter.apply(undefined, arguments));
  }, _Mixin$create.any = function (callback) {
    var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    false && !(typeof callback === 'function') && (0, _debug.assert)('`any` expects a function as first argument.', typeof callback === 'function');

    return any(this, callback, target);
  }, _Mixin$create.isAny = function () {
    return any(this, iter.apply(undefined, arguments));
  }, _Mixin$create.reduce = function (callback, initialValue) {
    false && !(typeof callback === 'function') && (0, _debug.assert)('`reduce` expects a function as first argument.', typeof callback === 'function');

    var ret = initialValue;

    this.forEach(function (item, i) {
      ret = callback(ret, item, i, this);
    }, this);

    return ret;
  }, _Mixin$create.invoke = function (methodName) {
    for (_len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    var ret = A(),
        _len,
        args,
        _key;

    this.forEach(function (item) {
      return ret.push((0, _emberUtils.tryInvoke)(item, methodName, args));
    });

    return ret;
  }, _Mixin$create.toArray = function () {
    return this.map(function (item) {
      return item;
    });
  }, _Mixin$create.compact = function () {
    return this.filter(function (value) {
      return value != null;
    });
  }, _Mixin$create.includes = function (object, startAt) {
    return indexOf(this, object, startAt, true) !== -1;
  }, _Mixin$create.sortBy = function () {
    var sortKeys = arguments;

    return this.toArray().sort(function (a, b) {
      var i, key, propA, propB, compareValue;

      for (i = 0; i < sortKeys.length; i++) {
        key = sortKeys[i];
        propA = (0, _emberMetal.get)(a, key);
        propB = (0, _emberMetal.get)(b, key);
        // return 1 or -1 else continue to the next sortKey

        compareValue = (0, _compare.default)(propA, propB);


        if (compareValue) {
          return compareValue;
        }
      }
      return 0;
    });
  }, _Mixin$create.uniq = function () {
    return uniqBy(this);
  }, _Mixin$create.uniqBy = function (key) {
    return uniqBy(this, key);
  }, _Mixin$create.without = function (value) {
    if (!this.includes(value)) {
      return this; // nothing to do
    }

    // SameValueZero comparison (NaN !== NaN)
    var predicate = value === value ? function (item) {
      return item !== value;
    } : function (item) {
      return item === item;
    };
    return this.filter(predicate);
  }, _Mixin$create['@each'] = _deprecatedFeatures.ARRAY_AT_EACH ? (0, _emberMetal.computed)(function () {
    false && !false && (0, _debug.deprecate)('Getting the \'@each\' property on object ' + (0, _emberUtils.toString)(this) + ' is deprecated', false, {
      id: 'ember-metal.getting-each',
      until: '3.5.0',
      url: 'https://emberjs.com/deprecations/v3.x#toc_getting-the-each-property'
    });

    return (0, _emberMetal.eachProxyFor)(this);
  }).readOnly() : undefined, _Mixin$create));

  var OUT_OF_RANGE_EXCEPTION = 'Index out of range';

  function removeAt(array, start) {
    var len = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    if ('number' === typeof start) {
      if (start < 0 || start >= array.length) {
        throw new _error.default(OUT_OF_RANGE_EXCEPTION);
      }

      array.replace(start, len, EMPTY_ARRAY);
    }

    return array;
  }

  /**
    This mixin defines the API for modifying array-like objects. These methods
    can be applied only to a collection that keeps its items in an ordered set.
    It builds upon the Array mixin and adds methods to modify the array.
    One concrete implementations of this class include ArrayProxy.
  
    It is important to use the methods in this class to modify arrays so that
    changes are observable. This allows the binding system in Ember to function
    correctly.
  
  
    Note that an Array can change even if it does not implement this mixin.
    For example, one might implement a SparseArray that cannot be directly
    modified, but if its underlying enumerable changes, it will change also.
  
    @class MutableArray
    @uses EmberArray
    @uses MutableEnumerable
    @public
  */

  var MutableArray = _emberMetal.Mixin.create(ArrayMixin, _mutable_enumerable.default, {
    clear: function () {
      var len = this.length;
      if (len === 0) {
        return this;
      }

      this.replace(0, len, EMPTY_ARRAY);
      return this;
    },
    insertAt: function (idx, object) {
      if (idx > this.length) {
        throw new _error.default(OUT_OF_RANGE_EXCEPTION);
      }

      this.replace(idx, 0, [object]);
      return this;
    },
    removeAt: function (start, len) {
      return removeAt(this, start, len);
    },
    pushObject: function (obj) {
      this.insertAt(this.length, obj);
      return obj;
    },
    pushObjects: function (objects) {
      this.replace(this.length, 0, objects);
      return this;
    },
    popObject: function () {
      var len = this.length;
      if (len === 0) {
        return null;
      }

      var ret = (0, _emberMetal.objectAt)(this, len - 1);
      this.removeAt(len - 1, 1);
      return ret;
    },
    shiftObject: function () {
      if (this.length === 0) {
        return null;
      }

      var ret = (0, _emberMetal.objectAt)(this, 0);
      this.removeAt(0);
      return ret;
    },
    unshiftObject: function (obj) {
      this.insertAt(0, obj);
      return obj;
    },
    unshiftObjects: function (objects) {
      this.replace(0, 0, objects);
      return this;
    },
    reverseObjects: function () {
      var len = this.length;
      if (len === 0) {
        return this;
      }

      var objects = this.toArray().reverse();
      this.replace(0, len, objects);
      return this;
    },
    setObjects: function (objects) {
      if (objects.length === 0) {
        return this.clear();
      }

      var len = this.length;
      this.replace(0, len, objects);
      return this;
    },
    removeObject: function (obj) {
      var loc = this.length || 0,
          curObject;
      while (--loc >= 0) {
        curObject = (0, _emberMetal.objectAt)(this, loc);


        if (curObject === obj) {
          this.removeAt(loc);
        }
      }
      return this;
    },
    removeObjects: function (objects) {
      var i;

      (0, _emberMetal.beginPropertyChanges)();
      for (i = objects.length - 1; i >= 0; i--) {
        this.removeObject(objects[i]);
      }
      (0, _emberMetal.endPropertyChanges)();
      return this;
    },
    addObject: function (obj) {
      var included = this.includes(obj);

      if (!included) {
        this.pushObject(obj);
      }

      return this;
    },
    addObjects: function (objects) {
      var _this2 = this;

      (0, _emberMetal.beginPropertyChanges)();
      objects.forEach(function (obj) {
        return _this2.addObject(obj);
      });
      (0, _emberMetal.endPropertyChanges)();
      return this;
    }
  });

  /**
    Creates an `Ember.NativeArray` from an Array-like object.
    Does not modify the original object's contents. `A()` is not needed if
    `EmberENV.EXTEND_PROTOTYPES` is `true` (the default value). However,
    it is recommended that you use `A()` when creating addons for
    ember or when you can not guarantee that `EmberENV.EXTEND_PROTOTYPES`
    will be `true`.
  
    Example
  
    ```app/components/my-component.js
    import Component from '@ember/component';
    import { A } from '@ember/array';
  
    export default Component.extend({
      tagName: 'ul',
      classNames: ['pagination'],
  
      init() {
        this._super(...arguments);
  
        if (!this.get('content')) {
          this.set('content', A());
          this.set('otherContent', A([1,2,3]));
        }
      }
    });
    ```
  
    @method A
    @static
    @for @ember/array
    @return {Ember.NativeArray}
    @public
  */

  // Add Ember.Array to Array.prototype. Remove methods with native
  // implementations and supply some more optimized versions of generic methods
  // because they are so common.
  /**
  @module ember
  */
  /**
    The NativeArray mixin contains the properties needed to make the native
    Array support MutableArray and all of its dependent APIs. Unless you
    have `EmberENV.EXTEND_PROTOTYPES` or `EmberENV.EXTEND_PROTOTYPES.Array` set to
    false, this will be applied automatically. Otherwise you can apply the mixin
    at anytime by calling `Ember.NativeArray.apply(Array.prototype)`.
  
    @class Ember.NativeArray
    @uses MutableArray
    @uses Observable
    @public
  */
  var NativeArray = _emberMetal.Mixin.create(MutableArray, _observable.default, {
    objectAt: function (idx) {
      return this[idx];
    },
    replace: function (start, deleteCount) {
      var items = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : EMPTY_ARRAY;
      false && !Array.isArray(items) && (0, _debug.assert)('The third argument to replace needs to be an array.', Array.isArray(items));

      (0, _emberMetal.replaceInNativeArray)(this, start, deleteCount, items);

      return this;
    },
    copy: function (deep) {
      false && !false && (0, _debug.deprecate)('Using `NativeArray#copy` is deprecated', false, {
        id: 'ember-runtime.using-array-copy',
        until: '3.5.0'
      });

      if (deep) {
        return this.map(function (item) {
          return (0, _copy.default)(item, true);
        });
      }

      return this.slice();
    }
  });

  // Remove any methods implemented natively so we don't override them
  var ignore = ['length'];
  NativeArray.keys().forEach(function (methodName) {
    if (Array.prototype[methodName]) {
      ignore.push(methodName);
    }
  });

  exports.NativeArray = NativeArray = (_NativeArray = NativeArray).without.apply(_NativeArray, ignore);

  var A = void 0;

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.Array) {
    NativeArray.apply(Array.prototype);
    exports.A = A = function (arr) {
      return arr || [];
    };
  } else {
    exports.A = A = function (arr) {
      if (!arr) {
        arr = [];
      }
      return ArrayMixin.detect(arr) ? arr : NativeArray.apply(arr);
    };
  }

  exports.A = A;
  exports.NativeArray = NativeArray;
  exports.MutableArray = MutableArray;
  exports.default = ArrayMixin;
});