define('ember-ux-sauce/components/uxs-table', ['exports', 'ember-ux-sauce/templates/components/uxs-table', 'ember-ux-sauce/mixins/textable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsTable, _textable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _textable.default, {
    //
    // Properties
    // ---------
    /**
      The BEM base name for this component: "uxs-text"
       @property base
      @type     String
      @default  "uxs-text"
      @public
     */
    base: 'uxs-table',

    //
    // Private
    // ---------
    /**
      The path to the handlbars template
       @property layout
      @type     String
      @private
     */
    layout: _uxsTable.default,
    tagName: 'table',
    //
    // Arguments
    // ---------
    /**
      The text align direction, "left", "center", "right" or "justified". Defaults to no alignment (inheret)
       @argument align
      @default  null
      @type     String
    */

    /**
      Adds a color modifier to the component. Standard options are "white", "light", "mid", "grey", "dark", "primary", "accent", "success", "warning"  or "error". Defaults to no style (inheret).
       @argument color
      @type String
    */
    /**
      Stretch table to full width
       @argument fullWidth
      @default false
      @type Boolean
    */
    fullWidth: false,

    /**
      Add some vertical padding to the table
       @argument padding
      @default false
      @type Boolean
    */
    padding: false,

    /**
    The name of the component. This property also defaults to the value for test selectors.<br/>
     The default is true, which ensures the test selector is added. If set to false the test selector is not rendered.
       @argument name
      @type     Boolean|String
    */

    /**
      Style the table as a record table
       @argument record
      @default false
      @type Boolean
    */
    record: false,

    /**
      Adds a style modifier to the component. Standard options are "white", "light", "mid", "grey", "dark", "primary", "accent", "success", "warning"  or "error". Defaults to no style (inheret).
       @argument style
      @type String
    */

    /**
      Define the size of the component. Standard options are "giant", "huge", "large", "medium", "small", or "tiny". Defaults to no size (inhered)
       @argument size
      @default null
      @type String
    */

    /**
      Define the weight of the component. Standard options are "bold", "medium" or "light". Defaults to no weight (inhered)
       @argument weight
      @default null
      @type String
    */
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['fullWidth:full-width', 'padding', 'record']);
    }
  });
});