define('ember-ux-sauce/components/uxs-pane', ['exports', 'ember-ux-sauce/templates/components/uxs-pane', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsPane, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    /**
      The base css class name 'uxs-tabs'
       @field base
      @type String
     */
    base: 'uxs-window__pane',
    layout: _uxsPane.default,
    // Arguments
    /**
      Set to true to display an aside style pane
       @argument aside
      @type     Boolean
      @default  false
      @public
     */
    aside: false,
    /**
      Set to true to display a detail style pane
       @argument detail
      @type     Boolean
      @default  false
      @public
     */
    detail: false,
    /**
      Set to true to display an index style pane
       @argument index
      @type     Boolean
      @default  false
      @public
     */
    index: false,
    /**
      Set to true to display a main style pane
       @argument main
      @type     Boolean
      @default  false
      @public
     */
    main: false,
    /**
      Set the mobile order of the pane. 1 to 9 supported
       @argument order
      @type     Int
      @default  null
      @public
     */
    order: false,
    /**
      Set to true to add some padding to the pane
       @argument padded
      @type     Boolean
      @default  false
      @public
     */
    padded: false,
    /**
      Set to true to display a single style pane
       @argument single
      @type     Boolean
      @default  false
      @public
     */
    single: false,
    /**
      Set to true to display a splash style pane
       @argument splash
      @type     Boolean
      @default  false
      @public
     */
    splash: false,
    /**
      Set to true to display a split style pane
       @argument split
      @type     Boolean
      @default  false
      @public
     */
    split: false,
    /**
      Set the style of the panes background.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-pane--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    /**
      Set the type of pane, an alternative to using the bolean arguments for main, aside etc
       UXS ships with the following stock pane types: aside, detail, index, main, single, splash & split.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-pane--my-custom-type_
       @argument type
      @type     String
      @default  null
      @public
     */
    type: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['aside', 'detail', 'full', 'index', 'main', 'single', 'splash', 'split', 'padded', '*type', '*style', '*_order']);
    },

    // Computed
    _order: Ember.computed('order', 'aside', 'index', 'detail', 'main', function () {
      var order = Ember.get(this, 'order');

      if (!order) {
        if (Ember.get(this, 'aside')) {
          order = 1;
        } else if (Ember.get(this, 'main')) {
          order = 2;
        } else {
          order = 1;
        }
      }
      return 'mobile-order-' + order;
    })
  });
});