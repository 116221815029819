enifed('ember-meta/lib/meta', ['exports', 'ember-babel', '@ember/debug', '@ember/deprecated-features', 'ember-environment', 'ember-utils'], function (exports, _emberBabel, _debug, _deprecatedFeatures, _emberEnvironment, _emberUtils) {
    'use strict';

    exports.counters = exports.meta = exports.Meta = exports.UNDEFINED = undefined;
    exports.setMeta = setMeta;
    exports.peekMeta = peekMeta;
    exports.deleteMeta =
    /**
      Tears down the meta on an object so that it can be garbage collected.
      Multiple calls will have no effect.
    
      @method deleteMeta
      @for Ember
      @param {Object} obj  the object to destroy
      @return {void}
      @private
    */
    function (obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `deleteMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `deleteMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `deleteMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var meta = peekMeta(obj);
        if (meta !== undefined) {
            meta.destroy();
        }
    }
    /**
      Retrieves the meta hash for an object. If `writable` is true ensures the
      hash is writable for this object as well.
    
      The meta object contains information about computed property descriptors as
      well as any watched properties and other information. You generally will
      not access this information directly but instead work with higher level
      methods that manipulate this hash indirectly.
    
      @method meta
      @for Ember
      @private
    
      @param {Object} obj The object to retrieve meta for
      @param {Boolean} [writable=true] Pass `false` if you do not intend to modify
        the meta hash, allowing the method to avoid making an unnecessary copy.
      @return {Object} the meta hash for an object
    */
    ;
    exports.descriptorFor =
    /**
      Returns the CP descriptor assocaited with `obj` and `keyName`, if any.
    
      @method descriptorFor
      @param {Object} obj the object to check
      @param {String} keyName the key to check
      @return {Descriptor}
      @private
    */
    function (obj, keyName, _meta) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `descriptorFor` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `descriptorFor` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `descriptorFor` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var meta = _meta === undefined ? peekMeta(obj) : _meta;
        if (meta !== undefined) {
            return meta.peekDescriptors(keyName);
        }
    }
    /**
      Check whether a value is a CP descriptor.
    
      @method descriptorFor
      @param {any} possibleDesc the value to check
      @return {boolean}
      @private
    */
    ;
    exports.isDescriptor = function (possibleDesc) {
        // TODO make this return `possibleDesc is Descriptor`
        return possibleDesc !== undefined && possibleDesc !== null && typeof possibleDesc === 'object' && possibleDesc.isDescriptor === true;
    };

    var objectPrototype = Object.prototype;

    /**
    @module ember
    */
    var UNDEFINED = exports.UNDEFINED = (0, _emberUtils.symbol)('undefined');
    // FLAGS
    var SOURCE_DESTROYING = 1 << 1;
    var SOURCE_DESTROYED = 1 << 2;
    var META_DESTROYED = 1 << 3;

    var Meta = exports.Meta = function () {
        function Meta(obj) {
            this._parent = undefined;
            this._descriptors = undefined;
            this._watching = undefined;
            this._mixins = undefined;
            if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
                this._bindings = undefined;
            }
            this._deps = undefined;
            this._chainWatchers = undefined;
            this._chains = undefined;
            this._tag = undefined;
            this._tags = undefined;
            // initial value for all flags right now is false
            // see FLAGS const for detailed list of flags used
            this._flags = 0;
            // used only internally
            this.source = obj;
            // when meta(obj).proto === obj, the object is intended to be only a
            // prototype and doesn't need to actually be observable itself
            this.proto = obj.constructor === undefined ? undefined : obj.constructor.prototype;
            this._listeners = undefined;
            this._listenersFinalized = false;
        }

        Meta.prototype.isInitialized = function (obj) {
            return this.proto !== obj;
        };

        Meta.prototype.destroy = function () {
            if (this.isMetaDestroyed()) {
                return;
            }
            this.setMetaDestroyed();
            // remove chainWatchers to remove circular references that would prevent GC
            var chains = this.readableChains();
            if (chains !== undefined) {
                chains.destroy();
            }
        };

        Meta.prototype.isSourceDestroying = function () {
            return this._hasFlag(SOURCE_DESTROYING);
        };

        Meta.prototype.setSourceDestroying = function () {
            this._flags |= SOURCE_DESTROYING;
        };

        Meta.prototype.isSourceDestroyed = function () {
            return this._hasFlag(SOURCE_DESTROYED);
        };

        Meta.prototype.setSourceDestroyed = function () {
            this._flags |= SOURCE_DESTROYED;
        };

        Meta.prototype.isMetaDestroyed = function () {
            return this._hasFlag(META_DESTROYED);
        };

        Meta.prototype.setMetaDestroyed = function () {
            this._flags |= META_DESTROYED;
        };

        Meta.prototype._hasFlag = function (flag) {
            return (this._flags & flag) === flag;
        };

        Meta.prototype._getOrCreateOwnMap = function (key) {
            return this[key] || (this[key] = Object.create(null));
        };

        Meta.prototype._getOrCreateOwnSet = function (key) {
            return this[key] || (this[key] = new Set());
        };

        Meta.prototype._findInherited1 = function (key) {
            var pointer = this,
                map;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    return map;
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._findInherited2 = function (key, subkey) {
            var pointer = this,
                map,
                value;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    value = map[subkey];

                    if (value !== undefined) {
                        return value;
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._findInherited3 = function (key, subkey, subsubkey) {
            var pointer = this,
                map,
                submap,
                value;
            while (pointer !== null) {
                map = pointer[key];

                if (map !== undefined) {
                    submap = map[subkey];

                    if (submap !== undefined) {
                        value = submap[subsubkey];

                        if (value !== undefined) {
                            return value;
                        }
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype._hasInInheritedSet = function (key, value) {
            var pointer = this,
                set;
            while (pointer !== null) {
                set = pointer[key];

                if (set !== undefined && set.has(value)) {
                    return true;
                }
                pointer = pointer.parent;
            }
            return false;
        };
        // Implements a member that provides a lazily created map of maps,
        // with inheritance at both levels.


        Meta.prototype.writeDeps = function (subkey, itemkey, count) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot modify dependent keys for `' + itemkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var outerMap = this._getOrCreateOwnMap('_deps');
            var innerMap = outerMap[subkey];
            if (innerMap === undefined) {
                innerMap = outerMap[subkey] = Object.create(null);
            }
            innerMap[itemkey] = count;
        };

        Meta.prototype.peekDeps = function (subkey, itemkey) {
            var val = this._findInherited3('_deps', subkey, itemkey);
            return val === undefined ? 0 : val;
        };

        Meta.prototype.hasDeps = function (subkey) {
            var val = this._findInherited2('_deps', subkey);
            return val !== undefined;
        };

        Meta.prototype.forEachInDeps = function (subkey, fn) {
            var pointer = this,
                map,
                innerMap,
                i;
            var seen = void 0;
            var calls = void 0;
            while (pointer !== null) {
                map = pointer._deps;

                if (map !== undefined) {
                    innerMap = map[subkey];

                    if (innerMap !== undefined) {
                        for (var innerKey in innerMap) {
                            seen = seen === undefined ? new Set() : seen;
                            if (!seen.has(innerKey)) {
                                seen.add(innerKey);
                                if (innerMap[innerKey] > 0) {
                                    calls = calls || [];
                                    calls.push(innerKey);
                                }
                            }
                        }
                    }
                }
                pointer = pointer.parent;
            }
            if (calls !== undefined) {
                for (i = 0; i < calls.length; i++) {
                    fn(calls[i]);
                }
            }
        };

        Meta.prototype.writableTags = function () {
            return this._getOrCreateOwnMap('_tags');
        };

        Meta.prototype.readableTags = function () {
            return this._tags;
        };

        Meta.prototype.writableTag = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new tag for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var ret = this._tag;
            if (ret === undefined) {
                ret = this._tag = create(this.source);
            }
            return ret;
        };

        Meta.prototype.readableTag = function () {
            return this._tag;
        };

        Meta.prototype.writableChainWatchers = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new chain watcher for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var ret = this._chainWatchers;
            if (ret === undefined) {
                ret = this._chainWatchers = create(this.source);
            }
            return ret;
        };

        Meta.prototype.readableChainWatchers = function () {
            return this._chainWatchers;
        };

        Meta.prototype.writableChains = function (create) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot create a new chains for `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());
            var ret = this._chains,
                parent,
                parentChains;

            if (ret === undefined) {
                this._chains = ret = create(this.source);
                parent = this.parent;


                if (parent !== null) {
                    parentChains = parent.writableChains(create);

                    parentChains.copyTo(ret);
                }
            }
            return ret;
        };

        Meta.prototype.readableChains = function () {
            return this._findInherited1('_chains');
        };

        Meta.prototype.writeWatching = function (subkey, value) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot update watchers for `' + subkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var map = this._getOrCreateOwnMap('_watching');
            map[subkey] = value;
        };

        Meta.prototype.peekWatching = function (subkey) {
            var count = this._findInherited2('_watching', subkey);
            return count === undefined ? 0 : count;
        };

        Meta.prototype.addMixin = function (mixin) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot add mixins of `' + (0, _emberUtils.toString)(mixin) + '` on `' + (0, _emberUtils.toString)(this.source) + '` call addMixin after it has been destroyed.' : '', !this.isMetaDestroyed());

            var set = this._getOrCreateOwnSet('_mixins');
            set.add(mixin);
        };

        Meta.prototype.hasMixin = function (mixin) {
            return this._hasInInheritedSet('_mixins', mixin);
        };

        Meta.prototype.forEachMixins = function (fn) {
            var pointer = this,
                set;
            var seen = void 0;
            while (pointer !== null) {
                set = pointer._mixins;

                if (set !== undefined) {
                    seen = seen === undefined ? new Set() : seen;
                    // TODO cleanup typing here
                    set.forEach(function (mixin) {
                        if (!seen.has(mixin)) {
                            seen.add(mixin);
                            fn(mixin);
                        }
                    });
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype.writeDescriptors = function (subkey, value) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot update descriptors for `' + subkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var map = this._getOrCreateOwnMap('_descriptors');
            map[subkey] = value;
        };

        Meta.prototype.peekDescriptors = function (subkey) {
            var possibleDesc = this._findInherited2('_descriptors', subkey);
            return possibleDesc === UNDEFINED ? undefined : possibleDesc;
        };

        Meta.prototype.removeDescriptors = function (subkey) {
            this.writeDescriptors(subkey, UNDEFINED);
        };

        Meta.prototype.forEachDescriptors = function (fn) {
            var pointer = this,
                map,
                value;
            var seen = void 0;
            while (pointer !== null) {
                map = pointer._descriptors;

                if (map !== undefined) {
                    for (var key in map) {
                        seen = seen === undefined ? new Set() : seen;
                        if (!seen.has(key)) {
                            seen.add(key);
                            value = map[key];

                            if (value !== UNDEFINED) {
                                fn(key, value);
                            }
                        }
                    }
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype.addToListeners = function (eventName, target, method, once) {
            if (this._listeners === undefined) {
                this._listeners = [];
            }
            this._listeners.push(eventName, target, method, once);
        };

        Meta.prototype._finalizeListeners = function () {
            if (this._listenersFinalized) {
                return;
            }
            if (this._listeners === undefined) {
                this._listeners = [];
            }
            var pointer = this.parent,
                listeners;
            while (pointer !== null) {
                listeners = pointer._listeners;

                if (listeners !== undefined) {
                    this._listeners = this._listeners.concat(listeners);
                }
                if (pointer._listenersFinalized) {
                    break;
                }
                pointer = pointer.parent;
            }
            this._listenersFinalized = true;
        };

        Meta.prototype.removeFromListeners = function (eventName, target, method) {
            var pointer = this,
                listeners,
                index;
            while (pointer !== null) {
                listeners = pointer._listeners;

                if (listeners !== undefined) {
                    for (index = listeners.length - 4; index >= 0; index -= 4) {
                        if (listeners[index] === eventName && (!method || listeners[index + 1] === target && listeners[index + 2] === method)) {
                            if (pointer === this) {
                                listeners.splice(index, 4); // we are modifying our own list, so we edit directly
                            } else {
                                // we are trying to remove an inherited listener, so we do
                                // just-in-time copying to detach our own listeners from
                                // our inheritance chain.
                                this._finalizeListeners();
                                return this.removeFromListeners(eventName, target, method);
                            }
                        }
                    }
                }
                if (pointer._listenersFinalized) {
                    break;
                }
                pointer = pointer.parent;
            }
        };

        Meta.prototype.matchingListeners = function (eventName) {
            var pointer = this,
                listeners,
                index;
            // fix type
            var result = void 0;
            while (pointer !== null) {
                listeners = pointer._listeners;

                if (listeners !== undefined) {
                    for (index = 0; index < listeners.length; index += 4) {
                        if (listeners[index] === eventName) {
                            result = result || [];
                            pushUniqueListener(result, listeners, index);
                        }
                    }
                }
                if (pointer._listenersFinalized) {
                    break;
                }
                pointer = pointer.parent;
            }
            return result;
        };

        (0, _emberBabel.createClass)(Meta, [{
            key: 'parent',
            get: function () {
                var parent = this._parent,
                    proto;
                if (parent === undefined) {
                    proto = getPrototypeOf(this.source);

                    this._parent = parent = proto === null || proto === objectPrototype ? null : meta(proto);
                }
                return parent;
            }
        }]);
        return Meta;
    }();

    if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
        Meta.prototype.writeBindings = function (subkey, value) {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot add a binding for `' + subkey + '` on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            var map = this._getOrCreateOwnMap('_bindings');
            map[subkey] = value;
        };
        Meta.prototype.peekBindings = function (subkey) {
            return this._findInherited2('_bindings', subkey);
        };
        Meta.prototype.forEachBindings = function (fn) {
            var pointer = this,
                map;
            var seen = void 0;
            while (pointer !== null) {
                map = pointer._bindings;

                if (map !== undefined) {
                    for (var key in map) {
                        // cleanup typing
                        seen = seen === undefined ? Object.create(null) : seen;
                        if (seen[key] === undefined) {
                            seen[key] = true;
                            fn(key, map[key]);
                        }
                    }
                }
                pointer = pointer.parent;
            }
        };
        Meta.prototype.clearBindings = function () {
            false && !!this.isMetaDestroyed() && (0, _debug.assert)(this.isMetaDestroyed() ? 'Cannot clear bindings on `' + (0, _emberUtils.toString)(this.source) + '` after it has been destroyed.' : '', !this.isMetaDestroyed());

            this._bindings = undefined;
        };
    }

    var getPrototypeOf = Object.getPrototypeOf;
    var metaStore = new WeakMap();
    function setMeta(obj, meta) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `setMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `setMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `setMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        metaStore.set(obj, meta);
    }
    function peekMeta(obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `peekMeta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `peekMeta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `peekMeta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var pointer = obj;
        var meta = void 0;
        while (pointer !== undefined && pointer !== null) {
            meta = metaStore.get(pointer);
            // jshint loopfunc:true

            if (meta !== undefined) {
                return meta;
            }
            pointer = getPrototypeOf(pointer);
        }
    }var meta = exports.meta = function (obj) {
        false && !(obj !== null) && (0, _debug.assert)('Cannot call `meta` on null', obj !== null);
        false && !(obj !== undefined) && (0, _debug.assert)('Cannot call `meta` on undefined', obj !== undefined);
        false && !(typeof obj === 'object' || typeof obj === 'function') && (0, _debug.assert)('Cannot call `meta` on ' + typeof obj, typeof obj === 'object' || typeof obj === 'function');

        var maybeMeta = peekMeta(obj);
        // remove this code, in-favor of explicit parent
        if (maybeMeta !== undefined && maybeMeta.source === obj) {
            return maybeMeta;
        }
        var newMeta = new Meta(obj);
        setMeta(obj, newMeta);
        return newMeta;
    };
    exports.counters = void 0;

    /*
     When we render a rich template hierarchy, the set of events that
     *might* happen tends to be much larger than the set of events that
     actually happen. This implies that we should make listener creation &
     destruction cheap, even at the cost of making event dispatch more
     expensive.
    
     Thus we store a new listener with a single push and no new
     allocations, without even bothering to do deduplication -- we can
     save that for dispatch time, if an event actually happens.
     */
    function pushUniqueListener(destination, source, index) {
        var target = source[index + 1],
            destinationIndex;
        var method = source[index + 2];
        for (destinationIndex = 0; destinationIndex < destination.length; destinationIndex += 3) {
            if (destination[destinationIndex] === target && destination[destinationIndex + 1] === method) {
                return;
            }
        }
        destination.push(target, method, source[index + 3]);
    }
});