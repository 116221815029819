define('ember-ux-sauce/components/uxs-breadcrumb/link', ['exports', 'ember-ux-sauce/templates/components/uxs-breadcrumb/link', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _link, _styleable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_bemComponent.default, _styleable.default, _testable.default, {
    layout: _link.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-menu__item"
      @public
     */
    base: 'uxs-breadcrumb__item',
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    /**
      If set to true the link will be rendered small (detail) size
       @argument detail
      @default false
      @type Boolean
    */
    detail: false,
    // Methods
    init: function init() {
      this.registerModifiers(['detail', 'disabled']);
      this._super.apply(this, arguments);
    }
  });
});