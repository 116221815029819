define('ember-ux-sauce/mixins/weightable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      Define the weight of the component. Standard options are "bold", "medium" or "light". Defaults to no weight (inhered)
       @property weight
      @default null
      @type String
    */
    weight: null,

    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*weight']);
    }
  });
});