define('ember-ux-sauce/components/uxs-pill', ['exports', 'ember-ux-sauce/templates/components/uxs-pill', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable', 'ember-ux-sauce/mixins/clickable'], function (exports, _uxsPill, _bemComponent, _testable, _clickable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Pill = Ember.Component.extend(_bemComponent.default, _clickable.default, _testable.default, {
    // Attributes
    base: 'uxs-pill',
    layout: _uxsPill.default,
    size: 'small',
    // Computed
    hasPrefixIcon: Ember.computed.bool('prefixIcon'),
    hasSuffixIcon: Ember.computed.bool('suffixIcon'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['rounded', '*size', '*style', 'hasPrefixIcon:with-prefix-icon', 'hasSuffixIcon:with-suffix-icon']);
    }
  });

  Pill.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Pill;
});