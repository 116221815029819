define('ember-ux-sauce/components/uxs-form-label', ['exports', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-form-label'], function (exports, _testable, _bemComponent, _uxsFormLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormLabel = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__label',
    layout: _uxsFormLabel.default,
    tagName: 'label',
    init: function init() {
      this.registerModifiers(['disabled', 'error', 'required', 'success', 'warning', '*style']);
      this._super.apply(this, arguments);
    }
  });

  FormLabel.reopenClass({
    positionalParams: ['text']
  });

  exports.default = FormLabel;
});