define('ember-ux-sauce/components/uxs-menu', ['exports', 'ember-ux-sauce/templates/components/uxs-menu', 'ember-ux-sauce/mixins/alignable', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsMenu, _alignable, _styleable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _alignable.default, _styleable.default, _testable.default, {
    layout: _uxsMenu.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-menu"
      @public
     */
    base: 'uxs-menu',
    /**
      If true, the menu will include a border
       @argument bordered
      @type     boolean
      @default  true
      @public
     */
    bordered: true,
    /**
      If true, the menu will be displayed horizontally
       @argument horizontal
      @type     boolean
      @default  false
      @public
     */
    horizontal: false,
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    /**
      The text align direction, "left", "center", "right" or "justified". Defaults to no alignment (inheret)
       @argument align
      @default  null
      @type     String
    */
    /**
      If set to true the menu items will be rendered small (detail) size
       @argument detail
      @default false
      @type Boolean
    */
    detail: false,
    /**
      If set to true the menu items & links will include a left padding to account for icons.
       @argument hasLeftIcon
      @default false
      @type Boolean
    */
    hasLeftIcon: false,
    /**
      If set to true the menu items & links will include a right padding to account for icons.
       @argument hasRightIcon
      @default false
      @type Boolean
    */
    hasRightIcon: false,
    /**
      If set to true the menu items & links will include padding to account for secondary text.
       @argument hasSecondaryText
      @default false
      @type Boolean
    */
    hasSecondaryText: false,
    // Methods
    init: function init() {
      this.registerModifiers(['detail', 'horizontal', 'bordered']);
      this._super.apply(this, arguments);
    },

    // Actions
    actions: {
      closeMobileNav: function closeMobileNav() {
        Ember.set(this, 'isShowingNav', false);
        return true;
      }
    }
  });
});