define('ember-ux-sauce/components/uxs-form', ['exports', 'ember-ux-sauce/templates/components/uxs-form', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsForm, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    attributeBindings: ['novalidate'],
    base: 'uxs-form',
    layout: _uxsForm.default,
    novalidate: true,
    tagName: 'form',
    padding: true,
    // Computed
    isWhite: Ember.computed.equal('style', 'white'),
    // Events
    submit: function submit(e) {
      e.preventDefault();
      if (!Ember.get(this, 'disabled')) {
        var action = Ember.get(this, 'onSubmit'),
            results = this.validate();
        if (results === true && action) {
          action();
        } else {
          var invalidAction = Ember.get(this, 'onInvalid');
          if (invalidAction) {
            invalidAction(results);
          }
        }
      }
    },

    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['isWhite:white', '*style', 'padding']);
    },

    /**
     * Validate child form control components
     **/
    validate: function validate() {
      var errorMessages = [],
          formControls = this.get('childFormControls');

      formControls.forEach(function (formControl) {
        if (formControl.get('validator') && !formControl.get('validator.isValid')) {
          var errors = {
            name: formControl.get('name'),
            messages: formControl.get('validator.errors').mapBy('message'),
            message: formControl.get('validator.errors').mapBy('message').join(', ')
          };
          errorMessages.push(errors);
        }
        formControl.set('didValidate', true);
      });

      if (errorMessages.length !== 0) {
        return errorMessages;
      }
      return true;
    },
    /**
     * Find all child form components to be validated
     **/
    childFormControls: Ember.computed('childViews', function () {
      var findChildFormControls = function findChildFormControls(thisComponent) {
        var childViews = thisComponent.get('childViews'),
            childFormControls = childViews.filter(function (childView) {
          if (childView.element) {
            return childView.element.className.match('uxs-form__control') ? true : false;
          } else {
            return false;
          }
        });
        // look for nested children
        // childViews.forEach(function(childView) {
        //   childFormControls.addObjects(findChildFormControls(childView));
        // });
        return childFormControls;
      };
      return findChildFormControls(this);
    })
  });
});