define('ember-ux-sauce/components/uxs-list-link-item', ['exports', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable', 'ember-ux-sauce/templates/components/uxs-list-link-item'], function (exports, _bemComponent, _testable, _uxsListLinkItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_bemComponent.default, _testable.default, {
    base: 'uxs-list-item',
    layout: _uxsListLinkItem.default,
    // Computed
    parentStyle: Ember.computed('style', function () {
      var style = Ember.get(this, 'style');
      if (!Ember.isEmpty(style)) {
        return 'parent-' + style;
      }
    }),
    hasOneLine: Ember.computed.equal('lines', 1),
    hasTwoLines: Ember.computed.equal('lines', 2),
    hasThreeLines: Ember.computed.equal('lines', 3),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style', 'hasOneLine:one-line', 'hasTwoLines:two-lines', 'hasThreeLines:three-lines']);
    }
  });
});