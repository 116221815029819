define('ember-ux-sauce/mixins/textable', ['exports', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/alignable', 'ember-ux-sauce/mixins/colorable', 'ember-ux-sauce/mixins/sizeable', 'ember-ux-sauce/mixins/weightable', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _accessible, _alignable, _colorable, _sizeable, _weightable, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_accessible.default, _alignable.default, _colorable.default, _sizeable.default, _testable.default, _weightable.default, _bemComponent.default, {
    //
    // Arguments
    // ---------
    /**
      The text align direction, "left", "center", "right" or "justified". Defaults to no alignment (inheret)
       @property align
      @default  null
      @type     String
    */
    align: null,
    /**
      Define the size of the component. Standard options are "giant", "huge", "large", "medium", "small", or "tiny". Defaults to no size (inhered)
       @property size
      @default null
      @type String
    */
    size: null,
    /**
      Define the weight of the component. Standard options are "bold", "medium" or "light". Defaults to no weight (inhered)
       @property weight
      @default null
      @type String
    */
    weight: null
  });
});