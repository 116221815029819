define('ember-ux-sauce/components/uxs-list', ['exports', 'ember-ux-sauce/templates/components/uxs-list', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsList, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    layout: _uxsList.default,
    /**
      The base css class name 'uxs-list'
       @field base
      @type String
     */
    base: 'uxs-list',
    // Arguments
    /**
      Set to true if this lists items should include a border
       @argument bordered
      @type     Boolean
      @default  false
      @public
     */
    bordered: false,
    /**
      Set to true if this list should grow to fit the flex box parent
       @argument grow
      @type     Boolean
      @default  false
      @public
     */
    grow: false,
    /**
      Set the style of the splash component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-splash--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Computed
    parentStyle: Ember.computed('style', function () {
      var style = Ember.get(this, 'style');
      if (!Ember.isEmpty(style)) {
        return 'parent-' + style;
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style', 'grow']);
    }
  });
});