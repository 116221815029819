define("ember-mapbox-gl/components/mapbox-gl", ["exports", "ember-mapbox-gl/templates/components/mapbox-gl", "ember-mapbox-gl/utils/noop", "ember-mapbox-gl/-private/mapbox-loader"], function (_exports, _mapboxGl, _noop, _mapboxLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Component that creates a new [mapbox-gl-js instance](https://www.mapbox.com/mapbox-gl-js/api/#map):
  
    ```hbs
    {{#mapbox-gl initOptions=initOptions mapLoaded=(action 'mapLoaded') as |map|}}
  
    {{/mapbox-gl}}
    ```
  
    @class MapboxGL
    @yield {Hash} map
    @yield {Component} map.call
    @yield {Component} map.control
    @yield {Component} map.image
    @yield {Component} map.layer
    @yield {Component} map.marker
    @yield {Component} map.on
    @yield {Component} map.popup
    @yield {Component} map.source
  */
  var _default = Ember.Component.extend({
    layout: _mapboxGl.default,

    /**
      An options hash to pass on to the [mapbox-gl-js instance](https://www.mapbox.com/mapbox-gl-js/api/).
      This is only used during map construction, and updates will have no effect.
       @argument initOptions
      @type {Object}
    */
    initOptions: null,

    /**
      An action function to call when the map has finished loading. Note that the component does not yield until the map has loaded,
      so this is the only way to listen for the mapbox load event.
       @argument mapLoaded
      @type {Function}
    */
    mapLoaded: _noop.default,
    _loader: _mapboxLoader.default,
    _isMapLoaded: false,
    _glSupported: Ember.computed('_loader.Mapbox', function () {
      var Mapbox = Ember.get(this, '_loader.Mapbox');

      if (Mapbox) {
        return Mapbox.supported();
      }
    }).readOnly(),
    _map: Ember.computed('_loader.Mapbox', 'element', function () {
      var Mapbox = Ember.get(this, '_loader.Mapbox');
      var element = this.element;

      if (Mapbox && element) {
        var config = Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), 'mapbox-gl.map');
        var options = Ember.assign({}, config, Ember.get(this, 'initOptions'));
        options.container = element;
        var map = new Mapbox.Map(options);
        map.once('load', Ember.run.bind(this, this._onLoad, map));
        return map;
      }
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      _mapboxLoader.default.load(Ember.getOwner(this).resolveRegistration('config:environment')['mapbox-gl']);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._isMapLoaded) {
        var map = Ember.get(this, '_map'); // some map users may be late doing cleanup (seen with mapbox-draw-gl), so don't remove the map until the next tick

        Ember.run.next(map, map.remove);
      }
    },
    _onLoad: function _onLoad(map) {
      if (this.isDestroyed || this.isDestroying) {
        map.remove();
        return;
      }

      this.mapLoaded(map);
      Ember.set(this, '_isMapLoaded', true);
    }
  });

  _exports.default = _default;
});