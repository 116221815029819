define('ember-ux-sauce/components/uxs-callout', ['exports', 'ember-ux-sauce/templates/components/uxs-callout', 'ember-ux-sauce/mixins/alignable', 'ember-ux-sauce/mixins/testable', 'ember-ux-sauce/mixins/styleable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsCallout, _alignable, _testable, _styleable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_alignable.default, _bemComponent.default, _styleable.default, _testable.default, {
    base: 'uxs-callout',
    layout: _uxsCallout.default,
    style: "dark"
  });
});