define('ember-ux-sauce/components/uxs-navbar/logo', ['exports', 'ember-ux-sauce/templates/components/uxs-navbar/logo', 'ember-ux-sauce/mixins/accessible', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _logo, _accessible, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_accessible.default, _bemComponent.default, _testable.default, {
    layout: _logo.default,
    //
    // Arguments
    // ---------
    /**
      Alternative text for the image tag
       @argument alt
      @type     String
      @default  "Logo"
      @public
     */
    alt: 'Logo',
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-navbar__logo"
      @public
     */
    base: 'uxs-navbar__logo',
    /**
      Set to true to use an svg icon false for an image
       @argument svg
      @type     Boolean
      @default  true
      @public
     */
    svg: true,
    /**
      The path to either the image or svg file<br>
      Passed as the first positional parameter
       @argument base
      @type     String
      @default  "uxs-navbar__logo"
      @public
     */
    path: null
  }).reopenClass({
    positionalParams: ['path']
  });
});