define('ember-ux-sauce/components/uxs-pagination-controls', ['exports', 'ember-ux-sauce/templates/components/uxs-pagination-controls', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsPaginationControls, _bemComponent, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    layout: _uxsPaginationControls.default,
    //
    // Arguments
    // ---------
    /**
      The name of the component, will be used as the value for all test selectors.
      If set to false the test selector is not rendered.
       @argument name
      @default  true
      @type     {(boolean,string)}
      @public
    */
    name: true,
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-pagination"
      @public
     */
    base: 'uxs-pagination',
    /**
      The text of the pagination label
       @argument resultsLabel
      @type     String
      @default  "Results per page"
      @public
     */
    resultsLabel: "Results per page",
    /**
      Add any text to the start of the "1 - 25 of 360" text.
       e.g. "Showing 1 - 25 of 360"
       @argument countPrefix
      @type     String
      @default  ""
      @public
     */
    countPrefix: "",
    /**
      Add any text to the end of the "1 - 25 of 360" text.
       e.g. "1 - 25 of 360 aliens"
       @argument countSuffix
      @type     String
      @default  ""
      @public
     */
    countSuffix: "",
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.button--my-custom-style_
       @argument style
      @type     String
      @default  "light"
      @public
     */
    style: "light",
    /**
      The size of a "page"
       @argument size
      @type     Int
      @default  null
      @public
     */
    size: null,
    /**
      The index of the current page
       @argument page
      @type     Int
      @default  null
      @public
     */
    page: null,
    /**
      The total number of records
       @argument total
      @type     Int
      @default  null
      @public
     */
    total: null,
    /**
      This event is fired when the user changes the page size or the next and prev
      buttons are clicked
       @argument onChange
      @default  null
      @type     Action
      @public
    */
    onChange: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    // Computed
    resultsText: Ember.computed('countPrefix', 'start', 'end', 'total', 'countSuffix', function () {
      var countPrefix = Ember.get(this, 'countPrefix'),
          start = Ember.get(this, 'start'),
          end = Ember.get(this, 'end'),
          countSuffix = Ember.get(this, 'countSuffix');

      if (start && end) {
        return countPrefix + ' ' + start + ' - ' + end + ' of ' + Ember.get(this, 'total') + ' ' + countSuffix;
      } else {
        return '';
      }
    }),
    pages: Ember.computed('total', 'size', function () {
      return Ember.get(this, 'total') / Ember.get(this, 'size');
    }),
    start: Ember.computed('page', 'size', function () {
      var page = Ember.get(this, 'page') - 1;
      var size = Ember.get(this, 'size');

      return page * size + 1;
    }),
    end: Ember.computed('start', 'size', 'total', function () {
      var start = Ember.get(this, 'start');
      var size = Ember.get(this, 'size');
      var total = Ember.get(this, 'total');

      return Math.min(total, start + size - 1);
    }),
    isPreviousButtonDisabled: Ember.computed.lte('page', 1),
    isNextButtonDisabled: Ember.computed('end', 'total', function () {
      return Ember.get(this, 'end') >= Ember.get(this, 'total');
    }),
    handleChange: function handleChange() {
      var props = Ember.getProperties(this, 'page', 'size');

      Ember.get(this, 'onChange')(props);
    },

    actions: {
      changeSize: function changeSize(e) {
        Ember.set(this, 'size', parseInt(e.target.value));
        Ember.set(this, 'page', 1);
        this.handleChange();
      },
      changePage: function changePage(page) {
        Ember.set(this, 'page', parseInt(page));
        this.handleChange();
      }
    }
  });
});