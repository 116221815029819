define('ember-ux-sauce/components/uxs-form-control-row', ['exports', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-form-control-row'], function (exports, _testable, _bemComponent, _uxsFormControlRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__control-row',
    inline: true,
    layout: _uxsFormControlRow.default,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['disabled', 'inline']);
    }
  });
});