define('ember-ux-sauce/components/uxs-preamble', ['exports', 'ember-ux-sauce/templates/components/uxs-preamble', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/testable', 'ember-ux-sauce/mixins/styleable', 'ember-ux-sauce/mixins/clickable'], function (exports, _uxsPreamble, _bemComponent, _testable, _styleable, _clickable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _clickable.default, _styleable.default, _testable.default, {
    layout: _uxsPreamble.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-preamble"
      @public
     */
    base: 'uxs-preamble',
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    /**
      The name of the action to fire on click
      @argument onClick
      @default  null
      @type     {string}
      @public
    */
    /**
      Set to true to disable the component.
       This will activate it's disabled style and also prevent any actions from being fired.
       @argument disabled
      @type     Boolean
      @default  false
      @public
     */
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    }
  });
});