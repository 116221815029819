define('ember-ux-sauce/helpers/truncate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncate = truncate;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /* *
   * This helper should be used to wrap any text that might be truncated in the UI. It will add a title tag so the full text is visible to the user on hover.
   * */
  function truncate(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        text = _ref2[0],
        title /*, ...rest*/ = _ref2[1];

    if (Ember.isEmpty(title)) {
      title = text;
    }
    return Ember.String.htmlSafe('<span class="truncate-text" title="' + title + '">' + text + '</span>');
  }

  exports.default = Ember.Helper.helper(truncate);
});