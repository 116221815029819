define('ember-ux-sauce/mixins/accessible', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    attributeBindings: ['disabled', 'disabled:aria-disabled', 'label:aria-label', 'role'],
    /**
      Prevents the action from firing when true
      @property disabled
      @default  null
      @type     {boolean}
      @public
    */
    disabled: false,
    /**
      Sets the an aria-label attribute
      @property label
      @default  null
      @type     {(string)}
      @public
     */
    label: false,
    /**
      Sets the role attribute
      @property role
      @default  null
      @type     {(string)}
      @public
     */
    role: false
  });
});