define('ember-ux-sauce/components/uxs-header/background', ['exports', 'ember-bem-sauce/mixins/bem-component'], function (exports, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, {
    // Attributes
    attributeBindings: ['cssStyles:style'],
    base: 'uxs-header__image',
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style']);
    },

    // Computed
    cssStyles: Ember.computed('image', function () {
      var image = Ember.get(this, 'image');
      if (image) {
        return Ember.String.htmlSafe('background-image:url(' + image + ')');
      }
    })
  });
});