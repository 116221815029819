define('ember-ux-sauce/components/uxs-form-input', ['exports', 'ember-ux-sauce/mixins/accessible', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _accessible, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend(_accessible.default, _bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-form__input',
    type: 'text',
    // Computed
    isEmail: Ember.computed.equal('type', 'email'),
    isHidden: Ember.computed.equal('type', 'hidden'),
    isNumber: Ember.computed.equal('type', 'number'),
    isPassword: Ember.computed.equal('type', 'password'),
    isSearch: Ember.computed.equal('type', 'search'),
    isTel: Ember.computed.equal('type', 'tel'),
    isText: Ember.computed.equal('type', 'text'),
    isURL: Ember.computed.equal('type', 'url'),
    isWeek: Ember.computed.equal('type', 'week'),
    isMonth: Ember.computed.equal('type', 'month'),
    isYear: Ember.computed.equal('type', 'year'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.initModifiers();
      this.initModelComputedProperties();
    },

    /**
     * Set the properties to bind to BEM modifier classes
     */
    initModifiers: function initModifiers() {
      this.registerModifiers(['disabled', '*style', 'isEmail:email', 'error', 'isHidden:hidden', 'inline', 'isNumber:number', 'isPassword:password', 'isSearch:search', 'success', 'isTel:tel', 'isText:text', 'isURL:url', 'isWeek:week', 'isMonth:month', 'isYear:year', 'warning']);
    },

    /**
     * If this control has a model and name defined
     * we create an alias for the bound value.
     * We also create a one way computed property to
     * read the current validation state of the property.
     */
    initModelComputedProperties: function initModelComputedProperties() {
      var model = this.get('model'),
          propName = this.get('name');

      if (model && propName) {
        Ember.defineProperty(this, 'validator', Ember.computed.oneWay('model.validations.attrs.' + propName));
        // map the value to mode.property - this can be overridden by passing value
        // property into this component
        Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + propName));
      }
    }
  });
});