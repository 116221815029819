define('ember-ux-sauce/components/uxs-archive-form', ['exports', 'ember-ux-sauce/templates/components/uxs-archive-form'], function (exports, _uxsArchiveForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Properties
    layout: _uxsArchiveForm.default,
    tagName: '',
    heading: 'Are You Sure?',
    submitText: 'Confirm Archive',
    cancelText: 'Cancel',
    cancelRoute: null,
    icon: 'warning',
    loading: false,
    // Actions
    actions: {
      archive: function archive() {
        var action = Ember.get(this, "onConfirm");
        if (action) {
          return action();
        }
      },
      cancel: function cancel() {
        var action = Ember.get(this, "onCancel");
        if (action) {
          return action();
        }
      }
    }
  });
});