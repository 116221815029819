define('ember-ux-sauce/components/uxs-table-cell', ['exports', 'ember-ux-sauce/templates/components/uxs-table-cell', 'ember-ux-sauce/mixins/textable'], function (exports, _uxsTableCell, _textable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Cell = Ember.Component.extend(_textable.default, {
    base: 'uxs-table__cell',
    layout: _uxsTableCell.default,
    tagName: 'td',
    attributeBindings: ['colspan'],
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['highlight', 'small', 'numeric', 'section', 'record']);
    }
  });

  Cell.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Cell;
});