define('ember-ux-sauce/mixins/styleable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @property style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Methods
    init: function init() {
      this.registerModifiers(['*style']);
      this._super.apply(this, arguments);
    },

    registerModifiers: function registerModifiers() {
      if (this._super) {
        this._super.apply(this, arguments);
      }
    }
  });
});