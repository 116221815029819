define('ember-ux-sauce/mixins/alignable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      The text align direction, "left", "center", "right" or "justified". Defaults to no alignment (inheret)
       @property align
      @type String
    */
    align: null,

    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*align']);
    },

    registerModifiers: function registerModifiers() {
      if (this._super) {
        this._super.apply(this, arguments);
      }
    }
  });
});