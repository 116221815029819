define('ember-cli-google-viewer/components/ember-cli-google-viewer', ['exports', 'ember-cli-google-viewer/templates/components/ember-cli-google-viewer'], function (exports, _emberCliGoogleViewer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ember-cli-google-viewer'],
    layout: _emberCliGoogleViewer.default,
    url: null,
    width: '100%',
    height: '100%',
    pid: 'explorer',
    ehf: false,
    a: 'v',
    chrome: false,
    embedded: true,
    isLoading: true,
    display: Ember.String.htmlSafe('display:none;'),

    actions: {
      iFrameLoaded: function iFrameLoaded() {
        this.setProperties({
          isLoading: false,
          display: Ember.String.htmlSafe('display:block;')
        });
        if (!Ember.isNone(this.get('onViewerLoad'))) {
          this.get('onViewerLoad')();
        }
      }
    }
  });
});