define('ember-ux-sauce/components/uxs-notice', ['exports', 'ember-ux-sauce/templates/components/uxs-notice', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/testable'], function (exports, _uxsNotice, _bemComponent, _clickable, _testable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    This component can be used to display some key info to a user before the main content is displayed.
  
    ```hbs
    {{uxs-notice text style=style truncated=truncated}}
    ```
    @class UXS Notice
    @public
    @yield {Hash} notice
    @yield {Component} notice.icon uxs-icon
    @yield {Component} notice.link uxs-button-link
    @yield {Component} notice.button uxs-button
    @yield {Component} notice.text uxs-text
  */
  var Notice = Ember.Component.extend(_bemComponent.default, _clickable.default, _testable.default, {
    layout: _uxsNotice.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-notice"
      @public
     */
    base: 'uxs-notice',
    /**
      Set to true to tuncate text to one line
       @argument truncated
      @type     Boolean
      @default  false
      @public
     */
    truncated: false,
    /**
      Set the style of the component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    /**
      The name of the action to fire on click
      @argument onClick
      @default  null
      @type     {string}
      @public
    */
    /**
      Set to true to disable the component.
       This will activate it's disabled style and also prevent any actions from being fired.
       @argument disabled
      @type     Boolean
      @default  false
      @public
     */
    // Computed
    hasText: Ember.computed.bool('text'),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style', 'truncated', 'hasText:has-text']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        style: 'default',
        truncated: true
      };
    }
  });

  Notice.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Notice;
});