define('ember-ux-sauce/components/uxs-button-group', ['exports', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component', 'ember-ux-sauce/templates/components/uxs-button-group'], function (exports, _testable, _bemComponent, _uxsButtonGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attribues
    /**
      The base css class name 'uxs-tabs'
       @field base
      @type String
     */
    base: 'uxs-button-group',
    layout: _uxsButtonGroup.default,
    // Arguments
    /**
      Set the alignment of the buttons. This will only take effect if the group is in column layout (row=false)
       UXS ships with three align options: left, center (default) & right
       @argument align
      @type     String
      @default  "left"
      @public
     */
    align: 'left',
    /**
      Set to true to disable all buttons in the group.
       This will activate it's disabled style and also prevent any actions from being fired and links working.
       @argument disabled
      @type     Boolean
      @default  false
      @public
     */
    disabled: false,
    /**
      Set to true to add some horizontal padding to the group
       @argument padded
      @type     Boolean
      @default  false
      @public
     */
    padded: false,
    /**
      Set to true to display child buttons in a row instead of a column
       @argument row
      @type     Boolean
      @default  false
      @public
     */
    row: false,
    /**
      Set the style of the groups background.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-tabs--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Computed
    isAlignCenter: Ember.computed.equal('align', 'center'),
    isAlignLeft: Ember.computed.equal('align', 'left'),
    isAlignRight: Ember.computed.equal('align', 'right'),
    // Methods
    /**
     * init - state
     *
     * @return object  this
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.registerModifiers(['*align', 'disabled', 'inline', 'padded', 'row', '*size', '*style']);
    },

    /**
     * Init any property defaults
     */
    getDefaultProps: function getDefaultProps() {
      return {
        disabled: false,
        inline: false,
        mini: false
      };
    }
  });
});