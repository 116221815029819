define('ember-ux-sauce/utils/link-to-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = linkToParams;
  function linkToParams(title, routeName, params, queryParams) {
    var obj = Ember.A([]);

    if (!Ember.isEmpty(title)) {
      // add title if set
      obj.push(title);
    }
    // add route
    obj.push(routeName);

    // add route params
    if (Ember.isArray(params)) {

      params.forEach(function (p) {
        obj.push(p);
      });
    } else if (params) {
      obj.push(params);
    }

    // add query params
    if (queryParams) {
      obj.push({
        isQueryParams: true,
        values: queryParams
      });
    }
    return obj;
  }
});