define('ember-ux-sauce/components/uxs-div', ['exports', 'ember-ux-sauce/templates/components/uxs-div', 'ember-ux-sauce/mixins/clickable', 'ember-ux-sauce/mixins/testable', 'ember-ux-sauce/mixins/styleable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsDiv, _clickable, _testable, _styleable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _clickable.default, _styleable.default, _testable.default, {
    base: 'uxs-div',
    clear: false,
    layout: _uxsDiv.default,
    // Computed
    small: Ember.computed.equal('size', 'small'),
    medium: Ember.computed.equal('size', 'medium'),
    large: Ember.computed.equal('size', 'large'),
    huge: Ember.computed.equal('size', 'huge'),
    giant: Ember.computed.equal('size', 'giant'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['clear', 'small', 'medium', 'large', 'huge', 'giant']);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        size: 'medium'
      };
    }
  });
});