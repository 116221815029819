define('ember-ux-sauce/mixins/sizeable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      Define the size of the component. Standard options are "giant", "huge", "large", "medium", "small", or "tiny". Defaults to no size (inhered)
       @property size
      @default null
      @type String
    */
    size: null,
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*size']);
    },

    registerModifiers: function registerModifiers() {
      if (this._super) {
        this._super.apply(this, arguments);
      }
    }
  });
});