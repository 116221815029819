define('ember-ux-sauce/mixins/colorable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    align: null,
    // Computed
    _color: Ember.computed('color', function () {
      var color = Ember.get(this, 'color');
      if (color) {
        return 'c-' + color;
      }
    }),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['*style', '*_color']);
    },

    registerModifiers: function registerModifiers() {
      if (this._super) {
        this._super.apply(this, arguments);
      }
    }
  });
});