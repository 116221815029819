define('ember-ux-sauce/mixins/clickable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Attributes
    /**
      The name of the action to fire on click
      @property onClick
      @default  null
      @type     {string}
      @public
    */
    onClick: null,
    /**
      Add a role to the item for accessibility
      @property role
      @default  'button'
      @type     {string}
      @public
    */
    role: 'button',
    // Actions
    click: function click() {
      var action = Ember.get(this, 'onClick');
      if (!this.get('disabled') && action) {
        action();
      }
    },

    hasClickAction: Ember.computed.bool('onClick'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['disabled', 'hasClickAction:clickable']);
    }
  });
});