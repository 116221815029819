define('ember-ux-sauce/components/uxs-navbar', ['exports', 'ember-ux-sauce/templates/components/uxs-navbar', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsNavbar, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    // Attributes
    base: 'uxs-navbar',
    layout: _uxsNavbar.default,
    /**
      Define the menu icon that will toggle the offscreen menu
       @argument toggleIcon
      @type     string
      @default  "menu"
      @public
     */
    toggleIcon: 'menu',
    // Computed
    parentStyle: Ember.computed('style', function () {
      var style = Ember.get(this, 'style');
      if (!Ember.isEmpty(style)) {
        return 'parent-' + style;
      }
    }),
    // Methods
    init: function init() {
      this.registerModifiers(['fixed', '*style']);
      this._super.apply(this, arguments);
    },
    getDefaultProps: function getDefaultProps() {
      return {
        title: 'AppName',
        style: 'dark'
      };
    }
  });
});