define('ember-ux-sauce/components/uxs-list-subheading', ['exports', 'ember-ux-sauce/templates/components/uxs-list-subheading', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsListSubheading, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    A component to render a list subheading component
  
    ```hbs
    {{uxs-list-subheading "My Subheading"}}
    ```
    @class UXS List Subheading
    @public
    @yield {Hash} subheading
  */
  var Subheading = Ember.Component.extend(_bemComponent.default, {
    // Service
    subheadingState: Ember.inject.service('uxs-list-subheading-cache'),
    // Attributes
    /**
      The base css class name 'uxs-list__subheading'
       @field base
      @type String
     */
    base: 'uxs-list__subheading',
    layout: _uxsListSubheading.default,
    tagName: '',
    // Arguments
    /**
      Should the subheading have a border?
       @argument bordered
      @type     boolean
      @default  true
      @public
     */
    bordered: true,
    /**
      Set the style of the subheading component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-splash--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    style: null,
    // Computed
    showSubheading: Ember.computed('text', 'sort', function () {
      var text = Ember.get(this, 'text'),
          sort = Ember.get(this, 'sort'),
          currentValue = Ember.get(this, 'subheadingState.value'),
          currentSort = Ember.get(this, 'subheadingState.sort');

      if (text !== currentValue || sort !== currentSort) {
        // SIDE EFFECT - BAD
        Ember.set(this, 'subheadingState.value', text);
        Ember.set(this, 'subheadingState.sort', sort);
        return true;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style']);
    }
  });

  Subheading.reopenClass({
    positionalParams: ['text', 'sort']
  });

  exports.default = Subheading;
});