define('ember-ux-sauce/components/uxs-datalist-item', ['exports', 'ember-ux-sauce/templates/components/uxs-datalist-item', 'ember-ux-sauce/mixins/testable', 'ember-bem-sauce/mixins/bem-component'], function (exports, _uxsDatalistItem, _testable, _bemComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_bemComponent.default, _testable.default, {
    layout: _uxsDatalistItem.default,
    //
    // Arguments
    // ---------
    /**
      The BEM base name for this component
       @argument base
      @type     String
      @default  "uxs-datalist"
      @public
     */
    base: 'uxs-datalist__item',
    /**
      A string to display as the label
       @argument label
      @type     String
      @default  null
      @public
     */
    label: null,
    /**
      A string to display as the value
       @argument value
      @type     String
      @default  null
      @public
     */
    value: null,
    /**
      A string to display as the tooltip
       @argument tip
      @type     String
      @default  null
      @public
     */
    tip: null,
    /**
      A string to show if the value is empty
       @argument empty
      @type     String
      @default  "N/A"
      @public
     */
    empty: 'N/A',
    /**
      If true, each data item will have a bottom border
       @argument bordered
      @type     boolean
      @default  true
      @public
     */
    bordered: true,
    /**
      The name of the component, will be used as the value for all test selectors.
      If set to false the test selector is not rendered.
       @argument name
      @default  true
      @type     {(boolean,string)}
      @public
    */
    /**
      Set the style of the datalist component.
       UXS ships with the following stock styles: primary, accent, warning, error, dark, grey, mid, light & white.
       You can customise your component by using any string here and adding your own css for the custom modifier e.g. _.uxs-datalist--my-custom-style_
       @argument style
      @type     String
      @default  null
      @public
     */
    //
    // Computed
    //
    hasLabel: Ember.computed.bool('label'),
    hasValue: Ember.computed.bool('value'),
    hasEmpty: Ember.computed.and('isNull', 'empty'),
    hasContent: Ember.computed.or('hasValue', 'hasEmpty'),
    hasTip: Ember.computed.bool('tip'),
    isNull: Ember.computed.not('hasValue'),
    // Methods
    init: function init() {
      this._super.apply(this, arguments);
      this.registerModifiers(['bordered', '*style']);
    }
  });
});