define('ember-ux-sauce/components/uxs-action-menu/desktop', ['exports', 'ember-ux-sauce/templates/components/uxs-action-menu/desktop', 'ember-ux-sauce/utils/link-to-params'], function (exports, _desktop, _linkToParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Attributes
    layout: _desktop.default,
    style: 'dark',
    tagName: '',
    loading: false,
    // Computed
    items: Ember.computed('routes', 'model.id', 'loading', function () {
      var items = [],
          routes = Ember.get(this, 'routes'),
          modelId = Ember.get(this, 'model.id');

      routes.forEach(function (route) {
        var title = Ember.get(route, 'title'),
            routeName = Ember.get(route, 'route'),
            queryParams = Ember.get(route, 'queryParams'),
            params = (0, _linkToParams.default)(title, routeName, modelId, queryParams);
        items.push({
          params: params,
          disabled: route.disabled,
          currentWhen: route.currentWhen ? route.currentWhen : routeName
        });
      });

      return items;
    })
  });
});